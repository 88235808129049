<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            @on-click="onClickTabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name+(item.number?'('+item.number+')':'')">
        </TabPane>
        <Icon custom="i-icon icon-shezhi"
              size="24"
              class="cursor-pointer"
              @click.stop="onClickSet"
              slot="extra"></Icon>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center">
          <!-- 6.1 -->
          <!-- <Checkbox v-model="postpone"
                    class="m-r-10">申请延期</Checkbox> -->
          <Checkbox v-model="overdue"
                    class="m-r-10">逾期</Checkbox>
          <MyTreeSelect :value="departName"
                        placeholder="责任部门"
                        :data="departmentTreeArr"
                        search
                        clearable
                        @onSelectChange="onChangeDepart"
                        class="m-r-10"
                        style="width:160px">
          </MyTreeSelect>
          <MyTreeSelect :value="personName"
                        placeholder="责任人"
                        clearable
                        :data="userTreeArr"
                        search
                        @onSelectChange="onChangePerson"
                        class="m-r-10"
                        style="width:160px">
          </MyTreeSelect>
          <Select v-model="questBigType"
                  placeholder="问题大类"
                  clearable
                  class="m-r-10"
                  style="width:160px">
            <Option v-for="item in questBigTypeArr"
                    :value="item.id"
                    :key="item.id">{{ item.name }}</Option>
          </Select>
          <Select v-model="questSmallType"
                  placeholder="问题小类"
                  clearable
                  :disabled="!questBigType"
                  class="m-r-10"
                  style="width:160px">
            <Option v-for="item in questSmallTypeArr"
                    :value="item.id"
                    :key="item.id">{{ item.name }}</Option>
          </Select>
          <Input v-model="workOrderNumber"
                 maxlength="30"
                 v-intLimit
                 clearable
                 placeholder="工单号"
                 class="m-r-10"
                 style="width:160px" />
          <Select v-model="workOrderLevel"
                  placeholder="工单等级"
                  clearable
                  class="m-r-10"
                  style="width:160px">
            <Option v-for="item in workOrderLevelArr"
                    :value="item.casesetLevelId"
                    :key="item.casesetLevelId">{{ item.name }}</Option>
          </Select>
          <DatePicker type="daterange"
                      :value="date"
                      :start-date="new Date()"
                      placeholder="请选择时间范围"
                      @on-change="onChangeDate"
                      style="width:260px"></DatePicker>
        </div>
        <div class="flex j-end m-t-10">
          <Button type="primary"
                  :ghost="buttonIndex == 0 ? false : true"
                  class="m-l-10"
                  v-if="checkPermi(['/admin/patrolCase/query'])"
                  @click.stop="onClickCurrentMonth">本月</Button>
          <Button type="primary"
                  :ghost="buttonIndex == 1 ? false : true"
                  class="m-l-10"
                  v-if="checkPermi(['/admin/patrolCase/query'])"
                  @click.stop="onClickLastMonth">上月</Button>
          <Button type="primary"
                  :ghost="buttonIndex == 2 ? false : true"
                  class="m-l-10"
                  v-if="checkPermi(['/admin/patrolCase/query'])"
                  @click.stop="onClickCurrentWeek">本周</Button>
          <Button type="primary"
                  :ghost="buttonIndex == 3 ? false : true"
                  class="m-l-10"
                  v-if="checkPermi(['/admin/patrolCase/query'])"
                  @click.stop="onClickNearlyThreeDay">近三天</Button>
          <Button type="primary"
                  class="m-l-10"
                  v-if="checkPermi(['/admin/patrolCase/query'])"
                  @click.stop="onClickSearch">
            <Icon custom="i-icon icon-sousuo"
                  size="16"></Icon>
            查询
          </Button>
          <Button class="m-l-10"
                  v-if="checkPermi(['/admin/patrolCase/query'])"
                  @click.stop="onClickReset">
            <Icon custom="i-icon icon-shuaxin"
                  size="16"></Icon>
            重置
          </Button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex">
        <Button type="primary"
                class="m-r-10 "
                v-if="checkPermi(['/admin/patrolCase/add'])"
                @click.stop="patrolCaseVisible = true">
          <Icon custom="i-icon icon-tianjia1"
                size="16"></Icon>
          添加
        </Button>
        <!-- <Dropdown @on-click="onClickPigeonhole">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-baocun"
                  size="16"></Icon>
            归档
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="lastMonth">归档上月</DropdownItem>
            <DropdownItem name="select">归档选择</DropdownItem>
          </DropdownMenu>
        </Dropdown> -->
        <Dropdown>
          <Button class="m-r-10"
                  v-hasPermi="['/admin/patrolCase/export']">
            <Icon custom="i-icon icon-shangchuan"
                  size="16"></Icon>
            导出
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="part">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('part')">
                    <Button>
                      导出部分
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
            <DropdownItem name="now">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('now')">
                <Button>
                  导出本页
                </Button>
              </ExportTemplate>
            </DropdownItem>
            <DropdownItem name="all">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('all')">
                <Button>
                  导出全部
                </Button>
              </ExportTemplate>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Dropdown @on-click="onClickBatch"
                  v-if="checkPermi(['/admin/patrolCase/delete'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-xiugai1"
                  size="16"></Icon>
            批量操作
          </Button>
          <DropdownMenu slot="list">
            <!-- <DropdownItem name="settleLawsuit">批量结案</DropdownItem>
            <DropdownItem name="urgeDo">批量催办</DropdownItem>
            <DropdownItem name="abandon">批量废弃</DropdownItem>
            <DropdownItem name="recover">批量恢复</DropdownItem> -->
            <DropdownItem name="delete"
                          v-if="checkPermi(['/admin/patrolCase/delete'])">批量删除</DropdownItem>
            <!-- <DropdownItem name="ignore">批量忽略</DropdownItem> -->
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               :row-class-name="rowClassName"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="video">
            <div v-previewVideo="row.videoUrl"
                 style="width: 24px;height: 24px;background: #797979;margin: auto;cursor: pointer"
                 v-if="row.videoUrl">
              <Icon custom="i-icon icon-bofang"
                    size="16"
                    color='#fff'></Icon>
            </div>
          </template>
          <template slot-scope="{ row }"
                    slot="operation">
            <template v-if="row.status == 1">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickEditCase(row)">编辑</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'结案')">结案</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'指派')">指派</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'废弃')">废弃</Button>
            </template>
            <template v-if="row.status == 2">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'处理')">处理</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'结案')">结案</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'改派')">改派</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'催办')">催办</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'废弃')">废弃</Button>
            </template>
            <template v-if="row.status == 3">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'结案')">结案</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'废弃')">废弃</Button>
            </template>
            <template v-if="row.status == 4">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'废弃')">废弃</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/delete']"
                      @click.stop="onClickDeleteCase(row)">删除</Button>
            </template>
            <template v-if="row.status == 5">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/edit']"
                      @click.stop="onClickStatusCase(row,'恢复')">恢复</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/patrolCase/delete']"
                      @click.stop="onClickDeleteCase(row)">删除</Button>
            </template>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>

    <!-- 新增 / 修改巡查工单 -->
    <PatrolCaseModal v-model="patrolCaseVisible"
                     :dataId.sync="patrolCaseId"
                     @onClickConfirm="getList"
                     @onChange='onChangClearRowStyle'></PatrolCaseModal>

    <!-- 问题详情 -->
    <PatrolCaseDetailModal v-model="patrolCaseDetailVisible"
                           :dataId.sync="patrolCaseDetailId"
                           @onChange='onChangClearRowStyle'></PatrolCaseDetailModal>

    <!-- 问题类型 -->
    <PatrolCaseQuestTypeModal v-model="patrolCaseQuestTypeVisible"
                              :item.sync="patrolCaseQuestTypeObj"
                              @onClickConfirm="getList"
                              @onChange='onChangClearRowStyle'></PatrolCaseQuestTypeModal>

    <!-- 查看地址 -->
    <PositionDetailModal v-model="positionDetailVisible"
                         :address="positionAddress"
                         :lng="positionLng"
                         :lat="positionLat"
                         @onChange='onChangClearRowStyle'></PositionDetailModal>

    <!-- 指派 分派 -->
    <PatrolCaseAssignModal v-model="patrolCaseAssignVisible"
                           :type="patrolCaseAssignType"
                           :item.sync="patrolCaseAssignObj"
                           :lnglat.sync="patrolCaseAssignLnglat"
                           @onClickConfirm="onChangeTableButton"
                           @onChange='onChangClearRowStyle'></PatrolCaseAssignModal>

    <!-- 处理 -->
    <PatrolCaseManageModal v-model="patrolCaseManageVisible"
                           :dataId.sync="patrolCaseManageId"
                           @onClickConfirm="onChangeTableButton"
                           @onChange='onChangClearRowStyle'></PatrolCaseManageModal>

    <!-- 结案 -->
    <PatrolCaseCloseModal v-model="patrolCaseCloseVisible"
                          :dataId.sync="patrolCaseCloseId"
                          @onClickConfirm="onChangeTableButton"
                          @onChange='onChangClearRowStyle'></PatrolCaseCloseModal>

    <!-- 催办 -->
    <PatrolCaseUrgeModal v-model="patrolCaseUrgeVisible"
                         :dataId.sync="patrolCaseUrgeId"
                         @onClickConfirm="getList"
                         @onChange='onChangClearRowStyle'></PatrolCaseUrgeModal>

    <!-- 批量归档 -->
    <PatrolCaseArchiveModal v-model="patrolCaseArchiveVisible"
                            @onChange='onChangClearRowStyle'></PatrolCaseArchiveModal>

    <!-- 工单设置 -->
    <PatrolCaseWorkOrderModal v-model="patrolCaseWorkOrderVisible"
                              @onChange='onChangClearRowStyle'></PatrolCaseWorkOrderModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { getCurrentMonth, getLastMonth, getCurrentWeek, getNearlyThreeDay } from '@/utils/dateRangUtil'
import PatrolCaseModal from '@/components/product/admin/modal/patrolMaintain/PatrolCaseModal'
import PatrolCaseDetailModal from '@/components/product/admin/modal/patrolMaintain/PatrolCaseDetailModal'
import PatrolCaseQuestTypeModal from '@/components/product/admin/modal/patrolMaintain/PatrolCaseQuestTypeModal'
import PositionDetailModal from '@/components/product/main/template/PositionDetailModal'
import PatrolCaseAssignModal from '@/components/product/admin/modal/patrolMaintain/PatrolCaseAssignModal'
import PatrolCaseManageModal from '@/components/product/admin/modal/patrolMaintain/PatrolCaseManageModal'
import PatrolCaseCloseModal from '@/components/product/admin/modal/patrolMaintain/PatrolCaseCloseModal'
import PatrolCaseUrgeModal from '@/components/product/admin/modal/patrolMaintain/PatrolCaseUrgeModal'
import PatrolCaseArchiveModal from '@/components/product/admin/modal/patrolMaintain/PatrolCaseArchiveModal'
import PatrolCaseWorkOrderModal from '@/components/product/admin/modal/patrolMaintain/PatrolCaseWorkOrderModal'
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    MyTreeSelect,
    PatrolCaseModal,
    PatrolCaseDetailModal,
    PatrolCaseQuestTypeModal,
    PositionDetailModal,
    PatrolCaseAssignModal,
    PatrolCaseManageModal,
    PatrolCaseCloseModal,
    PatrolCaseUrgeModal,
    PatrolCaseArchiveModal,
    PatrolCaseWorkOrderModal,
    ExportTemplate
  },
  data () {
    return {
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: '全部',
          number: 0
        },
        {
          name: '待分派',
          number: 0
        },
        {
          name: '待处理',
          number: 0
        },
        {
          name: '待结案',
          number: 0
        },
        {
          name: '已结案',
          number: 0
        },
        {
          name: '废弃工单',
          number: 0
        },
      ],
      caseId: '',
      // postpone: false,
      overdue: false,
      departId: '',
      departName: '',
      personId: '',
      personName: '',
      questBigType: '',
      questSmallType: '',
      questSmallTypeArr: [],
      workOrderNumber: '',
      workOrderLevel: '',
      date: [],
      sortKey: 'createTime',
      sortType: 'desc',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columns: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '工单号',
          key: 'caseNo',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickCaseDetail(row)
                }
              }
            }, row.caseNo);
          }
        },
        {
          title: '处理结果',
          key: 'status',
          width: 70,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let color = ''
            let text = ''
            switch (row.status) {
              case 1:
                color = '#ff1a1a'
                text = '待分派'
                break;
              case 2:
                color = '#fe9400'
                text = '待处理'
                break;
              case 3:
                color = '#0079fe'
                text = '待结案'
                break;
              case 4:
                color = '#007936'
                text = '已结案'
                break;
              case 5:
                color = '#8b8e86'
                text = '已废弃'
                break;
              default:
                break;
            }
            return h('span', [
              h('i', {
                style: {
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '2px',
                  background: color
                }
              }),
              h('span', text)
            ]);
          }
        },
        {
          title: '问题类型',
          key: 'caseIssueName',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text = row.caseIssueName + '-' + row.caseIssueChildName
            return h('span', {
              style: {
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickCaseQuestType(row)
                }
              }
            }, text);
          }
        },
        {
          title: '问题描述',
          key: 'des',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '问题地点',
          key: 'address',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickCaseAddress(row)
                }
              }
            }, row.address);
          }
        },
        {
          title: '整改前图片',
          key: 'imgUrl',
          width: 80,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.imgUrl) {
              let arr = row.imgUrl.split(',').filter(item => item)
              if (arr.length) {
                return h('el-image', {
                  props: {
                    src: arr[0],
                    'preview-src-list': arr
                  },
                  style: {
                    width: '22px',
                    height: '22px',
                    cursor: 'pointer'
                  }
                })
              }
            }
            return h('span', '')
          }
        },
        {
          title: '整改前视频',
          key: 'videoUrl',
          width: 80,
          tooltip: true,
          align: 'center',
          slot: 'video'
        },
        {
          title: '整改后图片',
          key: 'imgUrlOk',
          width: 80,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.imgUrlOk) {
              let arr = row.imgUrlOk.split(',').filter(item => item)
              if (arr.length) {
                return h('el-image', {
                  props: {
                    src: arr[0],
                    'preview-src-list': arr
                  },
                  style: {
                    width: '22px',
                    height: '22px',
                    cursor: 'pointer'
                  }
                })
              }
            }
            return h('span', '')
          }
        },
        // {
        //   title: '整改后视频',
        //   key: 'videoUrlOk',
        //   tooltip: true,
        //   width: 50,
        //   align: 'center',
        //   slot: 'video'
        // },
        {
          title: '工单等级(耗时)',
          key: 'caseLevelName',
          width: 100,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let isOverdue = row.isOverdue
            let text = ''
            if (row.status == 1) {
              text = `${row.caseLevelName}(限时：${row.timelimit}分钟)`
            } else if (isOverdue === 1) {
              text = `${row.caseLevelName}(逾期：${row.overdueTime})`
            } else {
              text = `${row.caseLevelName}(耗时：${row.consumingTime})`
            }
            return h('span', text)
          }
        },
        {
          title: '上报时间',
          key: 'createTime',
          width: 140,
          tooltip: true,
          sortable: 'custom',
          sortType: 'desc',
          align: 'center'
        },
        {
          title: '上报人',
          key: 'createName',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '责任部门',
          key: 'depName',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 210,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 新增 / 修改巡查案件
      patrolCaseVisible: false,
      patrolCaseId: '',
      // 问题详情
      patrolCaseDetailVisible: false,
      patrolCaseDetailId: '',
      // 问题类型
      patrolCaseQuestTypeVisible: false,
      patrolCaseQuestTypeObj: '',
      // 查看地址
      positionDetailVisible: false,
      positionAddress: '',
      positionLng: 0,
      positionLat: 0,
      // 指派/分派弹框
      patrolCaseAssignVisible: false,
      patrolCaseAssignType: '',
      patrolCaseAssignObj: '',
      patrolCaseAssignLnglat: {},
      // 处理弹框
      patrolCaseManageVisible: false,
      patrolCaseManageId: '',
      // 结案弹框
      patrolCaseCloseVisible: false,
      patrolCaseCloseId: '',
      // 催办弹框
      patrolCaseUrgeVisible: false,
      patrolCaseUrgeId: '',
      // 批量归档
      patrolCaseArchiveVisible: false,
      // 归档上月 弹框
      archiveConfig: {
        title: '是否确认此操作',
        content: '归档后，上月数据将不允许修改、删除？'
      },
      // 工单设置
      patrolCaseWorkOrderVisible: false
    };
  },
  watch: {
    questBigType (newVal) {
      let arr = []
      if (newVal) {
        let obj = this.questBigTypeArr.find(item => item.id === newVal)
        arr = obj ? obj.children : []
      }
      this.questSmallType = ''
      this.questSmallTypeArr = arr
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.caseId == items.caseId
          })
          this.selectIndexArr.push(data.caseId)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.caseId == items.caseId
          })
          this.selectVisitedIndexArr = [data.caseId]
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'userTreeArr': 'getUserTreeList',
    }),
    workOrderLevelArr () {
      let arr = [{
        casesetLevelId: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getWorkOrderLevelAllList)
    },
    questBigTypeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getWorkOrderQuestTypeTreeList)
    },
    getColumns () {
      return this.columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportPatrolCase
          break;
        case 1:
          path = this.$http.exportPersonAlarm
          break;
        case 2:
          path = this.$http.exportFacilityAlarm
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
      'updateUserTreeList',
      'updateWorkOrderLevelAllList',
      'updateWorkOrderQuestTypeTreeList',
    ]),
    init () {
      this.tableHeight = window.innerHeight - 390
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 390
      }
      this.updateDepartmentTreeList()
      this.updateUserTreeList()
      this.updateWorkOrderLevelAllList()
      this.updateWorkOrderQuestTypeTreeList()
      this.getPatrolCaseAmount()
      if (this.$route.params.status) {
        let params = this.$route.params
        this.currentTabIndex = params.status
        this.caseId = params.id
        this.questBigType = params.typeId
      }
      this.getList()
    },
    // 点击切换tab
    onClickTabs () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 工单设置
    onClickSet () {
      this.patrolCaseWorkOrderVisible = true
    },
    // 选择部门
    onChangeDepart (data) {
      console.log(data);
      this.departId = data.id
      this.departName = data.name
    },
    // 选择责任人
    onChangePerson (data) {
      this.personId = data.id
      this.personName = data.name
    },
    onChangeDate (date) {
      this.date = date
      this.buttonIndex = null
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth()
      this.onClickSearch()
      this.buttonIndex = 0
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      this.onClickSearch()
      this.buttonIndex = 1
    },
    // 本周
    onClickCurrentWeek () {
      this.date = getCurrentWeek()
      this.onClickSearch()
      this.buttonIndex = 2
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay()
      this.onClickSearch()
      this.buttonIndex = 3
    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.caseId)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.caseId)) {
        return 'table-bgcolor'
      }
    },
    onClickPigeonhole (name) {
      switch (name) {
        case 'lastMonth':
          console.log('lastMonth');
          break;
        case 'select':
          console.log('select');
          this.patrolCaseArchiveVisible = true
          break;
        default:
          break;
      }
    },
    onClickBatch (name) {
      if (!this.selectArr.length) return this.$Message.info('请选择案件')
      switch (name) {
        case 'settleLawsuit':
          console.log('settleLawsuit');
          break;
        case 'urgeDo':
          console.log('urgeDo');
          break;
        case 'abandon':
          console.log('abandon');
          break;
        case 'recover':
          console.log('recover');
          break;
        case 'delete':
          this.onDeleteCase(this.selectArr)
          break;
        case 'ignore':
          console.log('ignore');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.caseId)
      this.selectDataArr = selection
    },
    onChangeSort (e) {
      this.sortKey = e.key
      this.sortType = e.order
      this.onClickSearch()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.caseId = ''
      this.overdue = false
      this.departId = ''
      this.departName = ''
      this.personId = ''
      this.personName = ''
      this.questBigType = ''
      this.questSmallType = ''
      this.workOrderNumber = ''
      this.workOrderLevel = ''
      this.date = []
      this.sortKey = ''
      this.sortType = 'desc'
      this.buttonIndex = null
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    // 获取tab数量
    getPatrolCaseAmount () {
      this.$http.getPatrolCaseAmount().then(res => {
        if (res.code === 200) {
          res.result.forEach(item => {
            this.tabArr[item.status].number = item.count
          });
        }
      })
    },
    getList () {
      this.selectIndexArr = []
      this.getPatrolCaseList()
    },
    getPatrolCaseList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        caseId: this.caseId,
        status: this.currentTabIndex ? this.currentTabIndex : '',
        isPostpone: this.overdue ? 1 : 0,
        depId: this.departId,
        personId: this.personId,
        caseIssueId: this.questBigType ? this.questBigType : '',
        caseIssueChildId: this.questSmallType ? this.questSmallType : '',
        caseNo: this.workOrderNumber,
        caseLevelId: this.workOrderLevel ? this.workOrderLevel : '',
        startDate: '',
        endDate: '',
        sortField: this.sortKey,
        sortType: this.sortType
      }
      if (this.date.length > 0) {
        params.startDate = this.date[0]
        params.endDate = this.date[1]
      }
      this.tableLoading = true
      this.$http.getPatrolCaseList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.cases
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击案件详情
    onClickCaseDetail (row) {
      this.selectVisitedArr.push(row)
      this.patrolCaseDetailId = row.caseId
      this.patrolCaseDetailVisible = true
    },
    // 点击修改问题类型
    onClickCaseQuestType (row) {
      this.patrolCaseQuestTypeObj = row
      this.patrolCaseQuestTypeVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击查看地址
    onClickCaseAddress (row) {
      this.positionAddress = row.address
      this.positionLng = row.lng
      this.positionLat = row.lat
      this.positionDetailVisible = true
      this.selectVisitedArr.push(row)
    },
    // 修改案件
    onClickEditCase (row) {
      this.patrolCaseId = row.caseId
      this.patrolCaseVisible = true
      this.selectVisitedArr.push(row)
    },
    // 修改案件状态
    onClickStatusCase (row, status) {
      this.selectVisitedArr.push(row)
      switch (status) {
        case '指派':
          this.patrolCaseAssignType = '指派'
          this.patrolCaseAssignObj = row
          this.patrolCaseAssignVisible = true
          this.patrolCaseAssignLnglat = {
            lat: row.lat,
            lng: row.lng,
          }
          break;
        case '改派':
          this.patrolCaseAssignType = '改派'
          this.patrolCaseAssignObj = row
          this.patrolCaseAssignVisible = true
          this.patrolCaseAssignLnglat = {
            lat: row.lat,
            lng: row.lng,
          }
          break;
        case '催办':
          this.patrolCaseUrgeId = row.caseId
          this.patrolCaseUrgeVisible = true
          break;
        case '处理':
          this.patrolCaseManageId = row.caseId
          this.patrolCaseManageVisible = true
          break;
        case '结案':
          this.patrolCaseCloseId = row.caseId
          this.patrolCaseCloseVisible = true
          break;
        case '废弃':
          this.discardPatrolCase([row.caseId])
          break;
        case '恢复':
          this.regainPatrolCase([row.caseId])
          break;
        default:
          break;
      }
    },
    // 废弃案件
    discardPatrolCase (idArr) {
      this.$Modal.confirm({
        title: '是否确认此操作',
        content: '废弃该事件后，将无法进行下一步操作？',
        onOk: () => {
          let params = {
            caseIds: idArr.join()
          }
          this.$http.discardPatrolCase(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.onChangeTableButton()
            }
          }).catch(err => {
            this.$Message.info(err.message)
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 恢复案件
    regainPatrolCase (idArr) {
      this.$Modal.confirm({
        title: '是否确认此操作',
        content: '是否恢复此工单？',
        onOk: () => {
          let params = {
            caseIds: idArr.join()
          }
          this.$http.regainPatrolCase(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
             
              this.onChangeTableButton()
            }
          })
           this.onChangClearRowStyle()
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 删除案件
    onClickDeleteCase (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteCase([row.caseId])
    },
    onDeleteCase (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            caseIds: arr.join()
          }
          this.$http.deletePatrolCase(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangeTableButton()
              this.onChangClearRowStyle()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            alarmCarId: this.alarmId,
            carId: this.carNumberId,
            alarmTypeName: this.carAlarmType == 0 ? '' : this.carAlarmType,
            startDate: this.date.length == 0 ? '' : this.date[0],
            endDate: this.date.length == 0 ? '' : this.date[1],
          }
          if (value == 'now') {
            let ids = this.data.map(item => {
              return item.caseId
            })
            data.ids = ids.join()
          } else if (value == 'part') {
            data.ids = this.selectArr
            data.ids = data.ids.join()
          } else {
            // data.ids = ''
          }
          break;
        case 1:
          data = {
            alarmPersonId: this.alarmId,
            personId: this.personId,
            alarmTypeName: this.personAlarmType == 0 ? '' : this.personAlarmType,
            startDate: this.date.length == 0 ? '' : this.date[0],
            endDate: this.date.length == 0 ? '' : this.date[1],
          }
          if (value == 'now') {
            let ids = this.data.map(item => {
              return item.alarmCarId
            })
            data.ids = ids.join()
          } else if (value == 'part') {
            data.ids = this.selectArr
            data.ids = data.ids.join()
          } else {
            // data.ids = ''
          }
          break;
        case 2:
          data = {
            alarmSheshiId: this.alarmId,
            sheshiId: this.facilityId,
            sheshiName: this.facilityName,
            alarmTypeId: this.faclAlarmType == 0 ? '' : this.faclAlarmType,
            alarmTypeName: this.faclAlarmType == 0 ? '' : this.faclAlarmTypeName,
            startDate: this.date.length == 0 ? '' : this.date[0],
            endDate: this.date.length == 0 ? '' : this.date[1],
            facilitiesTypeId: this.facilityBigType ? this.facilityBigType : '',
            facilitiesTypeItemsId: this.facilitySmallType ? this.facilitySmallType : '',
          }
          if (value == 'now') {
            let ids = this.data.map(item => {
              return item.alarmSheshiId
            })
            data.ids = ids.join()
          } else if (value == 'part') {
            data.ids = this.selectArr
            data.ids = data.ids.join()
          } else {
            // data.ids = ''
          }
          break;
        default:
          break;
      }
      return data
    },
    // 表格按钮回调
    onChangeTableButton () {
      this.getList()
      this.getPatrolCaseAmount()
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;
    border: 2px solid #fff;
    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .table {
      margin-top: 16px;
    }
  }
}
</style>
